.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}
.MuiPaper-elevation2{
  box-shadow:none !important;
}
.text-white{
  color: #fff !important;
}
.MuiPaper-elevation0 {
  border: 1px solid #efefef !important;
  box-shadow: 2px 2px 4px -2px rgba(0, 0, 0, .1) !important;
}
.MuiCircularProgress-colorSecondary{
  color:#fff !important;
}
.page-loader-wrapper{
  position: fixed;
    text-align: center;
    width: 100%;
    height: 100%;
    z-index: 11111;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile_image{
  width:150px;
  height:150px;
  margin: auto;
  display:block;
}
.margin_chip{
  margin:5px;
}
.MuiInputBase-formControl{
  font-size: 15px !important;
}
.MuiFormControl-root,.MuiFormLabel-root{
  font-size: 14px !important;
  margin:1px !important;
}
.MuiInput-input{
  font-size:15px !important;
}
.MuiDialog-paperWidthMd{
  max-width:800px !important;
}
.chip{
  margin:5px !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
body{
  background-color:#f5f6fa !important;
}
.App-link {
  color: #09d3ac;
}
.text-center{
  text-align: center;
}
.tabletr td{
  vertical-align: middle !important;
}

.table_padding tr,.table_padding td,.table_padding th{
  padding:7px;
}
.border tr,.border table,.border th,.border td{
  border-collapse: collapse;
  border:1px solid #ece;
}
.light thead tr th{
  background-color: #eee;
  text-transform: uppercase;
}
.pull-right{
  float: right;
}
.table_padding th{
  vertical-align: baseline;
  text-align:left;
}
th.light{
  background-color:#eee;
}
.table_padding td{
  white-space:initial;
}
.width-full{
  width: 100% !important;
}
.col-centered{
    float: none;
    margin: 0 auto;
    display:block;
}
.otp_box{
  text-align: center;
  font-size:25px;
  letter-spacing:10px;
}
